import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="randomize-mobile-no"
export default class extends Controller {
  static targets = ['mobInputContainer', 'mobileField', 'randMobCheckBox', 'mobCheckBoxContainer']

  toggleMobContainer(event) {
    const mobInputContainer = this.mobInputContainerTarget;
    const customerMobileNo = mobInputContainer.querySelector('#customer_mobile_no');
    customerMobileNo.required = event.target.value !== 'true';
    mobInputContainer.classList.toggle('hidden');
  }

  validateMobNO(event) {
    if(this.hasRandMobCheckBoxTarget == false || this.randMobCheckBoxTarget.checked == false) {
      const mobNoTarget = this.mobileFieldTarget;
      if (mobNoTarget.value.length<10){
        mobNoTarget.classList.add("border-red-500");
        event.preventDefault();
      }
    }
  }

  toggleCheckBoxAndAlert(event){
    this.mobileFieldTarget.classList.remove("border-red-500");
    if (event.target.value.length >= 1) {
      this.mobCheckBoxContainerTarget.classList.add("hidden");
    } else {
      this.mobCheckBoxContainerTarget.classList.remove("hidden");
    }
  }
}
